<template>
  <div class="">
    <div class="bg-link-water p-4 rounded-lg">
      <PageTopOuter
        bottom-margin="mb-0"
        :count="count"
        icon="card_giftcard"
        label="Full Mock Exams"
        input-bg="bg-periwinkle-gray"
        :show-dialog="false"
        @on-create="createNewPracticeTest"
        @getKeyword="searchTests"
        @submit="onSubmit"
      />
    </div>
    <Table :items="practiceTestList" @onSelect="onSelect">
        <template slot="row" slot-scope="{ item }" md-selectable="single">
          <md-table-cell md-label="SL">{{ item.sl }}</md-table-cell>
          <md-table-cell md-label="TEST_ID">{{ item.t_id }}</md-table-cell>
          <md-table-cell md-label="TEST NAME">{{ item.title }}</md-table-cell>
          <md-table-cell md-label="TYPE">{{ item.type }}</md-table-cell>
          <md-table-cell md-label="CREATED BY">{{ item.created_by }}</md-table-cell>
          <md-table-cell md-label="CREATED AT">{{ item.created_at }}</md-table-cell>
          <md-table-cell md-label="STATUS">{{ (item.active == 1) ? 'Active' : 'Draft' }}</md-table-cell>
          <md-table-cell>
            <span class="py-1 mx-1 rounded">
              <md-icon class="m-0 text-victoria">bookmark</md-icon>
            </span>
            <router-link :to="{name: 'dataList.show', params: {id: item.id}}" class="mx-1 py-1 rounded">
              <md-icon class="m-0 text-victoria">visibility</md-icon>
            </router-link>
          </md-table-cell>
        </template>
      </Table>
      <div class="flex justify-between align-center my-6">
        <md-button class="bg-victoria text-white text-italic rounded ml-0" @click="$router.go(-1)">Back</md-button>
        <Paginate
          v-if="count > 20"
          :start="1"
          :end="20"
          :total="100"
          :limit="20"
        />
      </div>
    <Dialog>
      <TestCreationCreate />
    </Dialog>
  </div>
</template>

<script>
import PageTopOuter from "@/components/molecule/PageTopOuter";
import { Dialog, Table, Paginate } from "@/components";
import TestCreationCreate from "@/components/molecule/test-creation/TestCreationCreate";
import data from "@/data/test-creation/data";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    Table,
    Dialog,
    Paginate,
    PageTopOuter,
    TestCreationCreate,
  },
  data() {
    return {
      combo: data.combo,
      searchParams : new URLSearchParams(),
    }
  },
  computed: {
    ...mapGetters({
      practiceTestList : "testCreation/getPracticeTestList"
    }),
    count() {
      return this.practiceTestList.length
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog"
    }),
    ...mapActions({
      actPracticeTestList: "testCreation/actPracticeTestList"
    }),
    onSelect() {},
    searchTests({oldValue, newValue}) {
       // For reseting search result
        if(oldValue.length < newValue.length && oldValue.length == 0) {
          this.actPracticeTestList(`?${this.onPrepareQueryString('search', oldValue)}`);
        }
        // For submitting search api after 3 cherecter
        if(oldValue.length >= 3) {
          this.actPracticeTestList(`?${this.onPrepareQueryString('search', oldValue)}`);
        }
    },
    onSubmit(keyword) {
      this.actPracticeTestList(`?${this.onPrepareQueryString('search', keyword)}`);
    },
    onPrepareQueryString(key, value) {
      if(this.searchParams.has(key)) {
          this.searchParams.delete(key);
      }
      this.searchParams.append(key, value);
      return this.searchParams.toString();
    },
    createNewPracticeTest() {
      this.$router.push({
        name: 'testCreation.create',
        query: {type: 'mock-test'}
      })
    },
  },
  created() {
    this.searchParams.append('type', 'practice');
    this.searchParams.append('section', 'writing');
    this.actPracticeTestList(`?${this.searchParams.toString()}`);
  }
};
</script>
