<template>
  <div>
    <div class="rounded flex p-3 bg-swans-down mb-8">
      <i class="flaticon-discount text-primary text-lg mr-2"></i>
      <h3 class="text-uppercase text-primary">{{ label }}</h3>
    </div>

    <template v-for="item in items">
      <router-link :to="{ name: item.path }" :key="item.id">
        <div
          class="rounded flex p-5 justify-between mb-3"
          :style="{backgroundColor: item.color}"
        >
          <div class=" flex">
            <i class="text-white text-lg mr-2" :class="item.icon"></i>
            <h3 class="text-uppercase text-white">{{ item.name }}</h3>
          </div>
          <div>
            <span class="text-white">{{ item.test }} Test</span>
          </div>
        </div>
      </router-link>
    </template>
  </div>
</template>

<script>
export default {
  props: ['items', 'label']
};
</script>
