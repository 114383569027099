<template>
  <div class="accordion">
      <div v-for="(item, i) in items" :key="i">
        <div
          @click="toggleAccordion(item, i)"
          class="header rounded px-2 py-1 flex align-center pointer justify-between my-2"
          :class="bgHead"
        >
          <p class="text-white text-base text-uppercase">{{ item.name }}</p>
          <div class="action flex">
            <div class="outline-gray-400 px-1 rounded text-white text-xs flex align-center">2 Out of 3</div>
            <md-icon class="text-white ml-4">{{ (active !== i) ? 'add': 'remove'}}</md-icon>
          </div>
        </div>
        <div class="body " style="overflow-y: stickey; max-heignt: 18rem" v-if="active == i">
          <div
            class="item rounded p-1 m-2 pointer question-item"
            :class="bgBody"
            v-for="(question, i) in questions"
            :key="i"
          >
            <AccordionSingleItem :item="question"/>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import AccordionSingleItem from "@/components/atom/AccordionSingleItem";
import { mapGetters } from 'vuex';
export default {
  components: {
    AccordionSingleItem
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    bgHead: {
      type: String,
      default: () => 'bg-blue-chill'
    },
    bgBody: {
      type: String,
      default: () => 'bg-swans-down'
    },

  },
  data: () => ({
    active: 0,
    isChecked: true
  }),
  computed: {
    ...mapGetters({
      questions : 'question/getTypeWiseQuestions'
    })
  },
  methods: {
    toggleAccordion(item, index) {
      this.$emit('clickItem' ,item);
      this.active = index;
    },
    selectQuestion() {
      this.isChecked = !this.isChecked
    }
  }
}
</script>