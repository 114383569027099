const mockTestNavigation = [
    { 
        id: 1,
        name: 'Reading',
        test: 15,
        bgColor: '#4cb050',
        slug: 'reading',
        pill: 'Full',
        children: [
            {id: 1, name: 'Reading PTE 01', path: 'student.mockExamPreview'},
            {id: 2, name: 'Reading PTE 02', path: 'student.mockExamPreview'},
            {id: 3, name: 'Reading PTE 03', path: 'student.mockExamPreview'},
            {id: 4, name: 'Reading PTE 04', path: 'student.mockExamPreview'},
        ]
    },
    { 
        id: 2,
        name: 'Writing',
        test: 15,
        bgColor: '#066bb9',
        slug: 'writing',
        pill: 'Section',
        children: [
            {id: 1, name: 'Writing PTE 01', path: 'student.mockExamPreview'},
            {id: 2, name: 'Writing PTE 02', path: 'student.mockExamPreview'},
            {id: 3, name: 'Writing PTE 03', path: 'student.mockExamPreview'},
            {id: 4, name: 'Writing PTE 04', path: 'student.mockExamPreview'},
        ]
    },
    { 
        id: 3,
        name: 'Speaking',
        test: 15,
        bgColor: '#d4620c',
        slug: 'speaking',
        pill: 'Partial',
        children: [
            {id: 1, name: 'Speaking PTE 01', path: 'student.mockExamPreview'},
            {id: 2, name: 'Speaking PTE 02', path: 'student.mockExamPreview'},
            {id: 3, name: 'Speaking PTE 03', path: 'student.mockExamPreview'},
            {id: 4, name: 'Speaking PTE 04', path: 'student.mockExamPreview'},
        ]
    },
    { 
        id: 4,
        name: 'Listening',
        test: 15,
        bgColor: '#903578',
        slug: 'listening',
        pill: 'Full',
        children: [
            {id: 1, name: 'Listening PTE 01', path: 'student.mockExamPreview'},
            {id: 2, name: 'Listening PTE 02', path: 'student.mockExamPreview'},
            {id: 3, name: 'Listening PTE 03', path: 'student.mockExamPreview'},
            {id: 4, name: 'Listening PTE 04', path: 'student.mockExamPreview'},
        ]
    },

];

const mockExamNavigation = [
    { 
        id: 1,
        name: 'Full Mock Test',
        icon: "flaticon-discount",
        test: 10,
        color: '#4cb050',
        path: 'mockExam.FullMock'
    },
    { 
        id: 2,
        name: 'Section Wise Mock',
        icon: "flaticon-discount",
        test: 12,
        color: '#066bb9',
        path: 'dataList.all'
    },
    { 
        id: 3,
        name: 'Partial / Mini Mock',
        icon: "flaticon-discount",
        test: 15,
        color: '#d4620c',
        path: 'dataList.all'
    }
];


const mockExamDataList = [];

for (let i = 0; i < 15; i++) {
    mockExamDataList.push({
        id: i,
        title: 'The whole model test | April 2019, Bangladesh',
        count: '# 69',
        date: '24-05-2019'
    });
}

export default {
    mockTestNavigation, // For student
    mockExamNavigation, // For teacher
    mockExamDataList
}