<template>
    <div class="md-layout flex align-center px-3" @click="checkedQuestion()">
        <div class="md-layout-item md-size-60">
            <div class="text-uppercase font-bold flex">
                <span class="mr-4">{{ item.q_id }}</span>
                <span class="mr-4">{{ item.selected }}</span>
                <div v-html="item.index"></div>
            </div>
        </div>
        <div class="md-layout-item md-size-40 flex justify-end align-center">
            <md-icon class="m-0">{{ isChecked ? 'check_circle' : 'panorama_fish_eye' }}</md-icon>
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isChecked: false
        }
    },
    methods: {
        ...mapMutations({
            setQuestionIds : "testCreation/setQuestionsId"
        }),
        checkedQuestion() {
            this.isChecked = !this.isChecked;
            this.setQuestionIds(this.item.id);
        }
    }
}
</script>
