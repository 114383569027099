<template>
  <div class="p-6" style="width: 1000px;">
    <DialogTitle bg-color="bg-primary" label="Create Practice Test" align="center" />
    <form @submit.prevent="onSubmit">
      <div>
        <label class="text-uppercase font-bold text-xl mb-2 block mt-6"
          >Choose Section</label
        >
        <RadioButtonComponent
          active-class="font-bold"
          label-class="text-lg text-uppercase"
          :items="sections"
        />
      </div>
      <div class="md-layout md-gutter my-4">
        <div class="md-layout-item md-size-35">
          <InputFieldComponent
            label="Practice Test ID"
            label-class="text-xl"
            placeholder="PRT 1205"
            v-model.trim="$v.form.practiceTestId.$model"
            :message="!$v.form.practiceTestId.required && $v.form.practiceTestId.$dirty ? 'Field is required' : null"
          />
        </div>
        <div class="md-layout-item md-size-35">
          <InputFieldComponent
            label="Practice Test Name"
            label-class="text-xl"
            placeholder="SPLECIAL PRACTICE TEST"
            v-model.trim="$v.form.practiceTestName.$model"
            :message="!$v.form.practiceTestName.required && $v.form.practiceTestName.$dirty ? 'Field is required' : null"
          />
        </div>

      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-30">
          <label class="text-uppercase font-bold text-xl mb-2 block mt-6">
            Choose Create Type
          </label>
          <RadioButtonComponent
            active-class="font-bold"
            label-class="text-lg text-uppercase"
            :items="[{id: 1, label: 'Random'}, {id:2, label: 'Manual'}]"
            v-model.trim="$v.form.createType.$model"
            :message="!$v.form.createType.required && $v.form.createType.$dirty ? 'Field is required' : null"
          />
        </div>
        
        <div class="md-layout-item md-size-30">
          <label class="text-uppercase font-bold text-xl mb-2 block mt-6">Set Timing</label>
          <RadioButtonComponent
            active-class="font-bold"
            label-class="text-lg text-uppercase"
            :items="[{id: 1, label: 'Global'}, {id:2, label: 'Custom'}]"
          />
        </div>
        <div class="md-layout-item flex align-end md-size-30 mb-4">
          <InputFieldComponent
            class="w-24 mx-1"
            placeholder="mm"
            v-model.trim="$v.form.mm.$model"
            :message="!$v.form.mm.required && $v.form.mm.$dirty ? 'Field is required' : null"
          />
          <InputFieldComponent
            class="w-24 mx-1"
            placeholder="ss"
            v-model.trim="$v.form.ss.$model"
            :message="!$v.form.ss.required && $v.form.ss.$dirty ? 'Field is required' : null"
          />
          <InputFieldComponent
            class="w-24 mx-1"
            placeholder="Quantity"
            v-model.trim="$v.form.quantity.$model"
            :message="!$v.form.quantity.required && $v.form.quantity.$dirty ? 'Field is required' : null"
          />
        </div>
      </div>

      <AccordionComponent />

      <TextAreaComponent
        label="Short Description"
        v-model.trim="$v.form.description.$model"
        :message="!$v.form.description.required && $v.form.description.$dirty ? 'Field is required' : null"
      />
      <div class="flex justify-end mt-4">
        <md-button class="text-uppercase">Save as draft</md-button>
        <md-button type="submit" class="text-uppercase mr-0 bg-victoria text-white rounded">Publish</md-button>
      </div>
    </form>
  </div>
</template>

<script>
import { 
  DialogTitle,
  InputFieldComponent,
  TextAreaComponent,
  RadioButtonComponent
  } from "@/components"
import AccordionComponent from "@/components/molecule/AccordionComponent";
import { required } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";
export default {
  components: {
    RadioButtonComponent,
    AccordionComponent,
    InputFieldComponent,
    TextAreaComponent,
    DialogTitle
  },
  data() {
    return {
      sections: [
        {id:1, label: 'Reading'},
        {id:2, label: 'Writing'},
        {id:3, label: 'Speaking'},
        {id:4, label: 'Listening'}
      ],
      form: {
        practiceTestId: "",
        practiceTestName: "",
        createType: "",
        mm: "",
        ss: "",
        quantity: "",
        description: ""
      }
    };
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    onSubmit() {
      this.$v.$touch();
      if(this.$v.$invalid) {
        return;
      }
      this.setShowSnackbar('Test creation saved successfully !');
      setTimeout(() => {
       this.dialog(false);
      }, 1000);
    }
  },
  validations: {
    form: {
      practiceTestId: {required},
      practiceTestName: {required},
      createType: {required},
      mm: {required},
      ss: {required},
      quantity: {required},
      description: {required}
    }
  }
};
</script>

<style></style>
