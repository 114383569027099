<template>
  <div>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-100 md-layout md-gutter">
        <div class="md-layout-item md-size-100 mt-4">
          <PracticeList :items="items" label="Practice Test" />
        </div>
        <md-button class="bg-victoria text-white rounded text-italic mt-8" @click="$router.go(-1)">Back</md-button>
      </div>
    </div>
  </div>
</template>

<script>
import PracticeList from "@/components/molecule/practice/PracticeList";
import data from '@/data/test-creation/data'

export default {
  data: () => ({
    items: data.tabNavigation
  }),
  components: {
    PracticeList,
  },
};
</script>